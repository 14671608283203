import '@/assets/main.css'

import 'vue-multiselect/dist/vue-multiselect.css'

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import { useSearchStore } from '@/stores/search'
import { useAuthStore } from '@/stores/auth'
import { useErrorStore } from '@/stores/error'
import { useResetStore } from '@/stores/utils/reset-store'
import VueCookies from 'vue3-cookies'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { UserService } from './services/user-service'
import { DatadogService } from './services/datadog.service'
import PendoError from './util/errors/PendoError'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHeart, faFolder } from '@fortawesome/free-regular-svg-icons'

/* import specific icons */
import {
  faUserSecret,
  faShare,
  faUsers,
  faStar,
  faRuler,
  faChevronDown,
  faExpand,
  faEllipsisV,
  faHeart as solidHeart,
  faPenToSquare,
  faPlus
} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(
  faUserSecret,
  faShare,
  faUsers,
  faStar,
  faRuler,
  faChevronDown,
  faExpand,
  faHeart,
  faEllipsisV,
  faFolder,
  solidHeart,
  faPenToSquare,
  faPlus
)

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { mdi, aliases } from 'vuetify/iconsets/mdi-svg'
import { fa } from 'vuetify/iconsets/fa4'
import '@mdi/font/css/materialdesignicons.css'
import type { Router } from 'vue-router'
import { useFeatureFlagsStore } from './stores/feature-flag'
import AxiosError from './util/errors/AxiosError'

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...aliases
    },
    sets: {
      mdi,
      fa
    }
  },
  components,
  directives
})

const app = createApp(App)
const pinia = createPinia()

// Initialize error handler to report to datadog
app.config.errorHandler = (err, vm, info) => {
  console.error(err) // Make sure the errors are still thrown to the console.
  if (!(err instanceof AxiosError || err instanceof PendoError)) {
    DatadogService.addErrorWithStack(err, { error: err, component: vm, additionalInfo: info })
  }
}

declare module 'pinia' {
  export interface PiniaCustomProperties {
    router: Router
  }
}

pinia.use(piniaPluginPersistedstate)
pinia.use(({ store }) => {
  store.router = markRaw(router)
})

app.use(pinia)

const userService = new UserService()
const authStore = useAuthStore()
const errorStore = useErrorStore()
const resetStore = useResetStore()

if (authStore.authDisabled()) {
  const authToken = import.meta.env.VITE_AUTH_TOKEN

  // Manually set the auth cookie to play nice with sessions
  document.cookie = `auth=${authToken}; path=/;`
}

router.beforeEach(() => {
  // make sure the user is authenticated && a staff member
  if (!authStore.userCanAccess()) {
    authStore.clearUser()
  }
})

router.onError(() => {
  errorStore.setFullError(
    'Something went wrong',
    'Please try again, if this error persists please contact support.'
  )
  router.push({ name: 'error' })
})

app.use(router)
app.use(Toast)
app.use(VueCookies)
app.use(vuetify)

async function initializeApp() {
  const searchStore = useSearchStore()
  const featureFlagsStore = useFeatureFlagsStore()

  if (authStore.userExistsInSession()) {
    await authStore.loadUserFromStorage()
    if (authStore.userCanAccess()) {
      // user is authenticated go ahead and load the data
      await searchStore.init()
      DatadogService.startDatadog()
      await featureFlagsStore.initialize()
    } else {
      authStore.clearUser()
      window.location.href = `${
        import.meta.env.VITE_AUTH_OP_URI
      }/#/product-list?error=You do not have access to this experience. If you believe this is an error, please contact your district.`
    }
  }

  app.component('FontAwesomeIcon', FontAwesomeIcon).mount('#app')
}

declare global {
  interface Window {
    AppInit: any
    MessageBus: any
    PendoInit: any
  }
}

const onMessageBusMessage = (event: any) => {
  const message = window.MessageBus.getEventMessage(event)
  switch (message) {
    case window.MessageBus.EVENTS.AUTH_REAUTH:
      resetStore.all()
      authStore.storeLastPath()
      authStore.loadUserFromStorage(true)
      break
    case window.MessageBus.EVENTS.AUTH_INIT:
      authStore.loadUserFromStorage(true)
      authStore.navigateToLastPath()
      break
    case window.MessageBus.EVENTS.AUTH_401:
    case window.MessageBus.EVENTS.AUTH_LOGOUT:
      resetStore.all()
      // We don't set the storeLastPath here because the user is intentionally logging out
      // rather than having to re-authenticate
      authStore.clearUser()
      break
    case window.MessageBus.EVENTS.PRE_DE_AUTH:
      authStore.storeLastPath()
      authStore.clearUser()
      break
  }
}

/*
 * Initialize application with env
 * Initialize and listen to MessageBus
 */
document.addEventListener('DOMContentLoaded', async function () {
  const ieAuthConfig = {
    BASE_URL: import.meta.env.VITE_BASE_URL,
    RP_URI: import.meta.env.VITE_AUTH_RP_URI,
    LOGOUT_URI: import.meta.env.VITE_AUTH_LOGOUT_URI,
    CALLBACK_URI: import.meta.env.VITE_AUTH_CALLBACK_URI,
    RP_SESSION_CDN_URI: import.meta.env.VITE_AUTH_RP_SESSION_CDN_URI,
    OP_URI: import.meta.env.VITE_AUTH_OP_URI,
    OIDC_CLIENT_ID: import.meta.env.VITE_AUTH_OIDC_CLIENT_ID,
    OIDC_SESSION_STATE_COOKIE: import.meta.env.VITE_AUTH_OIDC_SESSION_STATE_COOKIE,
    OIDC_ISSUER: import.meta.env.VITE_AUTH_OIDC_ISSUER
  }

  if (!authStore.authDisabled()) {
    window.AppInit(ieAuthConfig)
    window.MessageBus.addEventListener(onMessageBusMessage)
  }

  if (authStore.userExistsInSession()) {
    await initializeApp()

    const userShouldHavePendo = authStore.userCanAccess() && authStore.user
    if (userShouldHavePendo && import.meta.env.VITE_ENV !== 'local') {
      let pendoObject = {}
      let initPendo = true

      try {
        pendoObject = {
          pendo_account: import.meta.env.VITE_PENDO_ACCOUNT_TOKEN,
          visitor_id: userService.getVisitorId(),
          account_id: userService.getAccountId(),
          account_name: userService.getAccountName(),
          crm_account_id: userService.getCrmAccountNumber()
        }
      } catch (error: any) {
        initPendo = false
        const pendoError = new PendoError(
          'Error initializing pendo',
          error,
          authStore.user?.account_id || 'not found',
          authStore.user?.client?.name || 'not found',
          authStore.user?.client_id || 'not found'
        )
        const errorDetails = `Error initializing pendo for account ${authStore.user?.account_id} in client ${authStore.user?.client?.name} with client id ${authStore.user?.client_id}`
        DatadogService.addErrorWithStack(pendoError, {
          errorMsg: error.message,
          details: errorDetails
        })
        console.warn('Failed to initialize pendo -- ', error.message)
      }

      if (initPendo) {
        window.PendoInit(pendoObject)
      }
    }
  }
})
