import { createPinia, getActivePinia } from 'pinia'
import type { Store } from 'pinia'

type ExtendedPinia = ReturnType<typeof createPinia> & {
  _s: Map<string, Store>
}

export function useResetStore() {
  const pinia = getActivePinia() as ExtendedPinia
  if (!pinia) {
    throw new Error('There is no active instance of Pinia')
  }

  const resetStore: Record<string, () => void> = {}

  pinia._s.forEach((store, name) => {
    resetStore[name] = store.$reset
  })

  resetStore.all = () =>
    pinia._s.forEach((store) => {
      store.$reset()
    })

  return resetStore
}
