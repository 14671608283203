export default class PendoError extends Error {
  public error: Error
  public accountId: string
  public clientName: string
  public clientId: string

  constructor(msg: string, error: Error, accountId: string, clientName: string, clientId: string) {
    super(`${msg} - ${error.message}`)
    this.name = this.constructor.name
    this.error = error
    this.accountId = accountId
    this.clientName = clientName
    this.clientId = clientId

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }
  }
}
