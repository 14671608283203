<script setup lang="ts">
import { RouterView } from 'vue-router'
import NavBar from './components/NavBar.vue'
import ExperienceDisabledView from './views/ExperienceDisabledView.vue'
import { useFeatureFlagsStore } from './stores/feature-flag'
import { computed } from 'vue'
import { FEATURE_FLAGS } from './util/feature-flags.constants'

const featureFlagsStore = useFeatureFlagsStore()
const isExperienceEnabled = computed(
  () => featureFlagsStore.flags[FEATURE_FLAGS.AM_CLIENT_ENABLED_FLAG]
)
</script>

<template>
  <v-layout v-if="isExperienceEnabled" class="layout_container">
    <NavBar v-if="!$route.meta.hideNavbar" />
    <v-main class="d-flex align-center justify-center main__content">
      <RouterView />
    </v-main>
  </v-layout>
  <v-layout v-if="!isExperienceEnabled" class="layout_container">
    <ExperienceDisabledView />
  </v-layout>
</template>

<style scoped>
.navbar {
  border-bottom: 1px solid #ccc;
}
.layout_container {
  position: relative;
}
</style>
